@font-face {
	font-family: ProximaNova;
	src:
		url('../src/assets/fonts/proxima-nova/proximanova-regular-webfont.woff2')
			format('woff2'),
		url('../src/assets/fonts/proxima-nova/proximanova-regular-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: ProximaNova;
	src:
		url('../src/assets/fonts/proxima-nova/proximanova-semibold-webfont.woff2')
			format('woff2'),
		url('../src/assets/fonts/proxima-nova/proximanova-semibold-webfont.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-weight: bold;
}

html {
	height: 100%;
}

body {
	margin: 0;
	font-family: ProximaNova, Helvetica, Arial, sans-serif;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

code {
	font-family:
		source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
