.grid,
.headerRow {
	background-color: #ffffff;
}

.headerRow {
	min-height: 40px;
}

.headerRow,
.evenRow,
.oddRow {
	border-bottom: 1px solid #eeeeee;
	color: #222222;
	display: flex;
	font-size: 13px;
}

.evenRow,
.oddRow {
	line-height: 40px;
	display: flex;
	align-items: center;
}

.headerRow:focus,
.evenRow:focus,
.oddRow:focus,
.headerColumn:focus,
.grid:focus {
	outline: none;
}

.headerColumn,
.column {
	text-overflow: ellipsis;
	padding: 0 10px;
}

.headerColumn {
	color: #444444;
	font-size: 13px;
	font-weight: bold;
	user-select: none;
	margin: auto;
	overflow: hidden;
}

.column {
	white-space: nowrap;
	overflow: hidden;
}

.noRows,
.maxRows {
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	text-align: center;
	color: #999999;
}

.placeholder {
	background-color: #ddd;
	display: inline-block;
	height: 1em;
	width: 500px;
}
